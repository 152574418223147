// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/max-dependencies */
import { Text } from "@clipboard-health/ui-react";
import { isDefined, isNullOrUndefined } from "@clipboard-health/util-ts";
import { IonPage } from "@ionic/react";
import { Box, Tab, Tabs } from "@mui/material";
import { AgentStage } from "@src/appV2/Agents";
import {
  attendanceScoreActionLabelMap,
  useGetAttendanceScoreHistory,
} from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreHistory";
import { useGetAttendanceScoreProfile } from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreProfile";
import { LatenessDeductionWarningAlert } from "@src/appV2/AttendanceScore/components/LatenessDeductionWarningAlert";
import { RestrictionDurationWarning } from "@src/appV2/AttendanceScore/components/RestrictionDurationWarning";
import { ScoreActivity } from "@src/appV2/AttendanceScore/components/ScoreActivity";
import { ScoreInformationTab } from "@src/appV2/AttendanceScore/components/ScoreInformationTab";
import { ScorePageHeader } from "@src/appV2/AttendanceScore/components/ScorePageHeader";
import {
  ATTENDANCE_SCORE,
  ATTENDANCE_SCORE_THRESHOLD_FOR_LATENESS_WARNING,
  CLIPBOARD_SCORE,
  DEFAULT_FINAL_RESTRICTION_DURATION,
  DEFAULT_FINAL_RESTRICTION_LIMIT,
  DEFAULT_FINAL_RESTRICTION_WINDOW,
  DEFAULT_RESTRICTION_COUNT,
  DEFAULT_RESTRICTION_DURATION_IN_DAYS,
  LATENESS_DEDUCTED_POINTS_THRESHOLD_FOR_WARNING,
} from "@src/appV2/AttendanceScore/constants";
import {
  useAttendanceScorePolicyLinks,
  useClipboardScoreLaunchDate,
  useClipboardScorePriorityAccessShiftsPerWeek,
  useClipboardScoreRollout,
  useDisableSecondShiftBonus,
} from "@src/appV2/AttendanceScore/hooks/featureFlags";
import { AppBarHeader, BackButtonLink, PageWithHeader } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { useATeamStatus } from "@src/appV2/Rankings/useATeamStatus";
import { useQualityBonusFlag } from "@src/appV2/Shifts/Shift/useQualityBonusFlag";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { USER_EVENTS } from "@src/constants";
import { isAfter, parseISO } from "date-fns";
import { first, isEmpty, startCase } from "lodash";
import { useState } from "react";

const FAQ_TAB = "faq";
const ACTIVITY_TAB = "activity";

// eslint-disable-next-line complexity, sonarjs/cognitive-complexity
export function ClipboardScorePage() {
  const [tab, setTab] = useState(FAQ_TAB);

  const {
    userId,
    qualification,
    tmz = Intl.DateTimeFormat().resolvedOptions().timeZone,
  } = useDefinedWorker();

  const {
    data: {
      score = 0,
      clipboardScore = 0,
      clipboardScoreRank: { rank = undefined, outOf: totalWorkersInRankPool = undefined } = {},
      restrictionCount,
      policy,
      accountStatus,
      latenessPointsDeductedInLast30Days,
      latenessBooktimeThreshold = 4,
      suspensionCount,
    } = {},
    refetch,
    isSuccess: successfullyGotAttendanceScoreProfile,
  } = useGetAttendanceScoreProfile(userId, {
    enabled: !isEmpty(userId),
  });

  const { data: attendanceScoreHistoryResponse, isSuccess: attendanceScoreHistoryIsSuccess } =
    useGetAttendanceScoreHistory({ userId }, { enabled: tab === ACTIVITY_TAB && !isEmpty(userId) });

  const { isWorkerAteamEnabled } = useATeamStatus();
  const isClipboardScoreEnabled = useClipboardScoreRollout();
  const clipboardScoreLaunchDate = useClipboardScoreLaunchDate();
  const attendanceScorePolicyLinks = useAttendanceScorePolicyLinks();
  const { disableSecondShiftBonus } = useDisableSecondShiftBonus();
  const priorityAccessShiftsPerWeek = useClipboardScorePriorityAccessShiftsPerWeek();
  const { enabled: qualityBonusIsEnabled } = useQualityBonusFlag();

  const showLatenessDeductionWarningAlert =
    successfullyGotAttendanceScoreProfile &&
    isDefined(score) &&
    (restrictionCount ?? -1) === 0 &&
    accountStatus?.status === AgentStage.ENROLLED &&
    score < ATTENDANCE_SCORE_THRESHOLD_FOR_LATENESS_WARNING &&
    (latenessPointsDeductedInLast30Days ?? 0) > LATENESS_DEDUCTED_POINTS_THRESHOLD_FOR_WARNING;

  useLogEffect(
    USER_EVENTS.SHOW_TIMELINESS_DEDUCTION_WARNING,
    {
      score,
      latenessPointsDeductedInLast30Days,
      suspensionCount,
    },
    { enabled: showLatenessDeductionWarningAlert }
  );

  const { scoreLogs } = attendanceScoreHistoryResponse ?? {};

  const scoreLogsPostClipboardScoreLaunch = scoreLogs?.filter((log) =>
    isAfter(parseISO(log.createdAt), parseISO(clipboardScoreLaunchDate))
  );

  const scoreLogsToDisplay = isClipboardScoreEnabled
    ? scoreLogsPostClipboardScoreLaunch
    : scoreLogs;

  const { restriction: restrictions, finalRestriction } = policy ?? {};

  function handleTabChange(newValue: string) {
    logEvent(APP_V2_USER_EVENTS.SWITCH_ATTENDANCE_SCORE_TABS, {
      tab: newValue,
    });
    setTab(newValue);
  }

  async function refreshEverything() {
    await refetch();
  }

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title={isClipboardScoreEnabled ? CLIPBOARD_SCORE : ATTENDANCE_SCORE}
            leftCta={<BackButtonLink defaultBackTo="home/ratings" />}
          />
        }
        onRefresh={async () => {
          await refreshEverything();
        }}
      >
        {isDefined(restrictionCount) && restrictionCount > 0 && (
          <RestrictionDurationWarning
            restrictionCount={restrictionCount ?? DEFAULT_RESTRICTION_COUNT}
            restrictionDuration={
              first(restrictions)?.duration ?? DEFAULT_RESTRICTION_DURATION_IN_DAYS
            }
            restrictionWindow={finalRestriction?.window ?? DEFAULT_FINAL_RESTRICTION_WINDOW}
            finalRestrictionDuration={
              finalRestriction?.duration ?? DEFAULT_FINAL_RESTRICTION_DURATION
            }
            restrictionLimit={finalRestriction?.restriction ?? DEFAULT_FINAL_RESTRICTION_LIMIT}
          />
        )}

        {showLatenessDeductionWarningAlert && (
          <LatenessDeductionWarningAlert
            latenessPointsDeductedInLast30Days={latenessPointsDeductedInLast30Days}
          />
        )}

        <ScorePageHeader
          score={isClipboardScoreEnabled ? clipboardScore : score}
          showPercentile={false}
          workerType={qualification ?? "Workers"}
          rank={isClipboardScoreEnabled ? rank : undefined}
          totalWorkers={isClipboardScoreEnabled ? totalWorkersInRankPool : undefined}
        />

        <Tabs
          centered
          value={tab}
          textColor="secondary"
          indicatorColor="secondary"
          variant="fullWidth"
          onChange={(_, newValue: string) => {
            handleTabChange(newValue);
          }}
        >
          <Tab value={FAQ_TAB} label="Score FAQ" />
          <Tab value={ACTIVITY_TAB} label="Recent Activity" />
        </Tabs>

        <Box paddingTop={2}>
          {tab === FAQ_TAB && (
            <ScoreInformationTab
              qualityBonusIsEnabled={qualityBonusIsEnabled}
              accountStatus={accountStatus?.status ?? undefined}
              accountReEnrollmentDate={accountStatus?.reEnrollmentDate}
              policy={policy}
              clipboardScore={clipboardScore}
              isClipboardScoreEnabled={isClipboardScoreEnabled}
              isWorkerATeamEnabled={isWorkerAteamEnabled}
              disableSecondShiftBonus={disableSecondShiftBonus}
              attendanceScorePolicyLinks={attendanceScorePolicyLinks}
              priorityAccessShiftsPerWeek={priorityAccessShiftsPerWeek}
              agentTimezone={tmz}
              latenessBooktimeThreshold={latenessBooktimeThreshold}
            />
          )}
          {tab === ACTIVITY_TAB &&
            (isEmpty(scoreLogsToDisplay) ? (
              <Box justifyContent="center" alignItems="center" display="flex">
                <Text bold variant="h3" color="text.secondary">
                  {attendanceScoreHistoryIsSuccess ? "No activities yet" : "Loading..."}
                </Text>
              </Box>
            ) : (
              <ScoreActivity
                isClipboardScoreEnabled={isClipboardScoreEnabled}
                activity={(scoreLogsToDisplay ?? []).map((scoreEntry) => ({
                  id: scoreEntry.id.toString(),
                  adjustmentDate: parseISO(scoreEntry.createdAt),
                  adjustmentReason:
                    attendanceScoreActionLabelMap.get(scoreEntry.action) ??
                    startCase(scoreEntry.action),
                  adjustmentAmount: isClipboardScoreEnabled
                    ? scoreEntry.clipboardScoreAdjustment
                    : scoreEntry.attendanceScoreAdjustment,
                  newScore: isClipboardScoreEnabled
                    ? scoreEntry.clipboardScoreUpdatedScore
                    : scoreEntry.attendanceScore,
                  adjustmentShiftStart: isNullOrUndefined(scoreEntry.shiftStart)
                    ? undefined
                    : parseISO(scoreEntry.shiftStart),
                  adjustmentLocation: scoreEntry.facilityName ?? undefined,
                }))}
              />
            ))}
        </Box>
      </PageWithHeader>
    </IonPage>
  );
}
